import React, { useState } from 'react'
import styles from '../../styles/Home.module.css'
import { Col, Container, Row } from 'react-bootstrap'
import CheckMark from '../../../public/svgs/CheckMark.svg'
import Link from 'next/link'
import { useWindowSize } from '@/hooks/WindowSize'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const QuickModule = () => {
  const [modules, setModules] = useState({
    performance: false,
    payroll: false,
    recruitment: false,
    coreHR: false,
    reports: false,
  })
  const { width } = useWindowSize()
  const ModuleSection = ({ title, subTitle, desc, firstCheck, secondCheck, thirdCheck, image, color, left, link }) => {
    return (
      <>
        <div className={styles.quickModuleBg} style={{ backgroundColor: color }}>
          <Container>
            <div className={styles.QuickModule}>
              <div className={styles.modulesInteraction} onClick={() => onModuleClick('coreHR')}>
                <p>Core HR</p>
              </div>
              <div className={styles.modulesInteraction} onClick={() => onModuleClick('payroll')}>
                <p>Payroll</p>
              </div>
              <div className={styles.modulesInteraction} onClick={() => onModuleClick('recruitment')}>
                <p>Recruitment</p>
              </div>
              <div className={styles.modulesInteraction} onClick={() => onModuleClick('performance')}>
                <p>Performance</p>
              </div>
              <div className={styles.modulesInteraction} onClick={() => onModuleClick('reports')}>
                <p>Reports</p>
              </div>
            </div>

            <Row style={{ marginBlock: 50 }}>
              {left ? (
                <>
                  <Col lg={7} md={12} style={{ marginTop: 40, marginLeft: -50 }}>
                    <LazyLoadImage src={image} style={{ width: '100%' }} />
                  </Col>
                  <Col lg={5} md={12} className={styles.leftQucikModule}>
                    <h2 className={styles.quicModuleTitle}>{title} </h2>
                    <p className={styles.quickModuleGradient}>{subTitle}</p>
                    <p style={{ marginBlock: 30 }}>{desc}</p>
                    <div className={styles.quickCheckMarks}>
                      <CheckMark width={size} />
                      <p style={{ margin: 0 }}>{firstCheck}</p>
                    </div>
                    <div className={styles.quickCheckMarks}>
                      <CheckMark width={size} />
                      <p style={{ margin: 0 }}>{secondCheck}</p>
                    </div>
                    <div className={styles.quickCheckMarks}>
                      <CheckMark width={size} />
                      <p style={{ margin: 0 }}>{thirdCheck}</p>
                    </div>
                    <div style={{ marginTop: 30 }}>
                      <Link href={link ? link : '/'} className={styles.learnButton}>
                        Learn More
                      </Link>
                    </div>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={5}>
                    <h2 className={styles.quicModuleTitle}>{title} </h2>
                    <p className={styles.quickModuleGradient}>{subTitle}</p>
                    <p style={{ marginBlock: 30 }}>{desc}</p>
                    <div className={styles.quickCheckMarks}>
                      <CheckMark width={size} />
                      <p style={{ margin: 0 }}>{firstCheck}</p>
                    </div>
                    <div className={styles.quickCheckMarks}>
                      <CheckMark width={size} />
                      <p style={{ margin: 0 }}>{secondCheck}</p>
                    </div>
                    <div className={styles.quickCheckMarks}>
                      <CheckMark width={size} />
                      <p style={{ margin: 0 }}>{thirdCheck}</p>
                    </div>
                    <div style={{ marginTop: 30 }}>
                      <Link href={link ? link : '/'} className={styles.learnButton}>
                        Learn More
                      </Link>
                    </div>
                  </Col>
                  <Col md={7} style={{ marginTop: 20 }}>
                    <LazyLoadImage src={image} style={{ width: '100%' }} />
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </div>
      </>
    )
  }

  const onModuleClick = (value) => {
    switch (value) {
      case 'coreHR':
        setModules({ ...modules, coreHR: false, reports: false, recruitment: false, payroll: false, performance: false })
        break
      case 'payroll':
        setModules({ ...modules, payroll: true, coreHR: true, performance: false, recruitment: false, reports: false })
        break
      case 'recruitment':
        setModules({ ...modules, recruitment: true, coreHR: true, payroll: false, performance: false, reports: false })
        break

      case 'performance':
        setModules({ ...modules, performance: true, payroll: false, recruitment: false, reports: false, coreHR: true })
        break
      case 'reports':
        setModules({ ...modules, reports: true, coreHR: true, recruitment: false, payroll: false, performance: false })
        break
      default:
    }
  }
  const size = '30px'
  return (
    <>
      {modules.coreHR === false && (
        <ModuleSection
          title={'Core HR'}
          subTitle={'Say Goodbye to Complicated HR Processes'}
          desc={"Looking for an HR system that simplifies your workload? Look no further than WebHR - the intuitive platform that does the heavy lifting for you Whether you're dealing with company structure, managing employee absences, or tackling any other HR challenge, WebHR has a solution that makes it easy. Say goodbye to complicated processes and hello to a streamlined approach to HR management."}
          firstCheck={'Simplifies HR workload'}
          secondCheck={'Streamlined HR management'}
          thirdCheck={'Comprehensive HR solution'}
          color={'#0869A0'}
          image={'https://cdn.webhr.co/Website/images/modules_illustration/CoreHR_Illustration.png'}
          link={'/modules/core-hr'}
        />
      )}
      {modules.payroll === true && (
        <ModuleSection
          title={'Payroll Module'}
          subTitle={'Streamline Payroll Management with Automated Solutions'}
          desc={
            "Simplify your payroll management with our automated end-to-end solution that covers everything from entering salaries into the system to producing monthly pay slips. Our platform encompasses all payroll components, such as salary modifications, deductions, bonuses, earnings, commissions, reimbursements, overtime, provident fund contributions, pensions, advance salary payments, loans, insurance, final settlements, and organizational expenses. Adaptable for individual branches or employee groups and embedded with tax laws for 75+ countries, our system guarantees precise and effective payroll processing, customized to suit your organization's specific needs."
          }
          firstCheck={'Automated payroll management'}
          secondCheck={'Compliant with tax laws'}
          thirdCheck={'Customizable for specific needs'}
          color={'#2708A0'}
          image={'https://cdn.webhr.co/Website/images/modules_illustration/Payroll_Illustration.png'}
          left
          link={'/modules/payroll'}
        />
      )}
      {modules.recruitment === true && (
        <ModuleSection
          title={'Recruitment Module'}
          subTitle={'Effortless Hiring with WebHR'}
          desc={
            "Initiating with the job requisition and then seamlessly posting into your company's website through WebHR integration or using the stand-alone link of WebHR for Job postings, which lets candidates to submit online applications and receive their own portal login to complete their profile. The system automatically synchronizes this information, streamlining the entire hiring process. This includes steps such as candidate shortlisting, interview scheduling, evaluation, and ultimately recruitment, offering a comprehensive end-to-end solution for your hiring needs."
          }
          firstCheck={'Streamlined hiring process'}
          secondCheck={'Competency assessment'}
          thirdCheck={'Comprehensive recruitment solution'}
          color={'#083DA0'}
          image={'https://cdn.webhr.co/Website/images/modules_illustration/Recruitment_Illustration.png'}
          link={'/modules/recruitment'}
        />
      )}

      {modules.performance === true && (
        <ModuleSection
          title={'Performance Module'}
          subTitle={'Performance Insights at Your Fingertips.'}
          desc={
            "Design your performance reviews within the system tailored to each job's unique objectives and KPIs. You can also assess your employees' competencies and identify their personal development needs and Roadmap for employee development through performance evaluations. Easily classify employee talent matrix standing. Gather reviews from employees, line managers, additional reviewers, and peers to gain 360-degree feedback on employee performance insight. Set the frequency of performance reviews and monitor their completion status. Obtain insightful reports on these evaluations with just a few clicks, streamlining the review process for your organization."
          }
          firstCheck={'Customized performance reviews'}
          secondCheck={'Competency assessment'}
          thirdCheck={'360-degree feedback'}
          image={'https://cdn.webhr.co/Website/images/modules_illustration/Performance_Illustration.png'}
          left
          link={'/modules/performance'}
        />
      )}
      {modules.reports === true && (
        <ModuleSection
          title={'Reports Module'}
          subTitle={'Get Actionable Insights with Customizable HR Reports'}
          desc={'The Reports Module functions as a vital tool for HR business intelligence, offering access to over 250 pre-built reports. With the customizable Reports Generator, you can create personalized reports using various filters and additional fields. These reports can be easily printed, shared via email directly from the system, or exported in five diverse formats.'}
          firstCheck={'250+ Pre-built Reports'}
          secondCheck={'Various Filters & Fields'}
          thirdCheck={'Print, Email, Export'}
          color={'#9F0A53'}
          image={'https://cdn.webhr.co/Website/images/modules_illustration/Reports_Illustration.png'}
          link={'/modules/core-hr'}
        />
      )}
    </>
  )
}

export default QuickModule
